import axios from 'axios'
import {get, post, put} from '../utils/request'
axios.prototype.get = get
axios.prototype.post = post
axios.prototype.put = put
export default {
    //qq微信登录地址
    userauth(data) {
        return axios.get('user/auth',data)
    },
    // 学生注册-发送短信验证码
    usersmsregist(data) {
        return axios.post('user/sms/regist',data)
    },
    // 学生注册
    userregist(data) {
        return axios.post('user/regist',data)
    },
    // 忘记密码-发送短信验证码
    usersmsresetpwd(data) {
        return axios.post('user/sms/resetpwd',data)
    },
    // 忘记密码
    userresetpwd(data) {
        return axios.post('user/resetpwd',data)
    },
    // 登录
    userlogin(data) {
        return axios.post('user/login',data)
    },
    // 修改登录密码
    changepw(data) {
        return axios.post('user/changepw',data)
    },
    // 更新用户基本信息
    userupdate(data) {
        return axios.post('user/update',data)
    },
     /**
        学生端
    */
    //获取我加入的课堂_列表
    courselist(data) {
        return axios.post('course/list',data)
    },
    //通过课堂码查询课堂
    coursecode(data) {
        return axios.post('course/code',data)
    },
    //加入课堂
    coursejoin(data) {
        return axios.post('course/join',data)
    },
    //加入课堂
    courseswitchTeam(data) {
        return axios.post('course/switchTeam',data)
    },
    courseteam(data) {
        return axios.post('course/team',data)
    },
    //获取课堂资料库_列表
    coursedoc(data) {
        return axios.post('course/doc',data)
    },
    //获取我的项目
    projectmy(data) {
        return axios.post('project/my',data)
    },
    //创建项目
    projectcreate(data) {
        return axios.post('project/create',data)
    },
    //项目详情
    projectdetail(data) {
        return axios.post('project/detail',data)
    },
    //获取项目基础信息
    projectbase(data) {
        return axios.post('project/base',data)
    },
    //更新项目基础信息
    projectbaseUpdate(data) {
        return axios.post('project/baseUpdate',data)
    },
    //项目目录选中更新
    projectchecked(data) {
        return axios.post('project/checked',data)
    },
    //获取一级目录项详情
    projectccatadt(data) {
        return axios.post('project/catadt',data)
    },
    //更新一级目录项
    projectccatagx(data) {
        return axios.post('project/catagx',data)
    },
    //删除项目
    projectcdel(data) {
        return axios.post('project/del',data)
    },
    //获取项目可视化图表
    projectcradar(data) {
        return axios.post('project/radar',data)
    },
    //获取项目打印数据
    projectcprint(data) {
        return axios.post('project/print',data)
    },

    











    /**
        教师端
    */
    //新建课堂
    coursecreate(data) {
        return axios.post('manage/course/create',data)
    },
    //获取课堂分页数据
    coursepage(data) {
        return axios.post('manage/course/page',data)
    },
    //获取课堂详情
    coursedetail(data) {
        return axios.post('manage/course/detail',data)
    },
    //获取课堂小组项目列表
    coursepjtlist(data) {
        return axios.post('manage/course/pjt/list',data)
    },
    //获取课堂小组项目详情
    coursepjtdetail(data) {
        return axios.post('manage/course/pjt/detail',data)
    },
    //课堂_删除
    coursedel(data) {
        return axios.post('manage/course/del',data)
    },
    // 获取课堂资料库_列表
    doclist(data) {
        return axios.post('manage/course/doc/list',data)
    },
    // 课堂资料库_添加
    docadd(data) {
        return axios.post('manage/course/doc/add',data)
    },
    // 课堂资料库_删除
    docdel(data) {
        return axios.post('manage/course/doc/del',data)
    },
    // 设置开课
    coursestart(data) {
        return axios.post('manage/course/start',data)
    },
    // 设置结课
    courseend(data) {
        return axios.post('manage/course/end',data)
    },
    // 设置结课
    coursepjtscore(data) {
        return axios.post('manage/course/pjt/score',data)
    },
    // 项目一级目录评语
    coursepjtremark(data) {
        return axios.post('manage/course/pjt/remark',data)
    },
    
    //教师端获取项目打印数据
    managecourseprint(data) {
        return axios.post('manage/course/print',data)
    },
}