<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  mounted(){
    // let beginTime = 0;//开始时间
    // let differTime = 0;//时间差
    // window.onunload = function (){
    //   differTime = new Date().getTime() - beginTime;
    //   if(differTime <= 5) localStorage.clear();
    // };
    // window.onbeforeunload = function (){
    //   beginTime = new Date().getTime();
    //   document.documentElement.scrollTop = 0;  //ie下
    //   document.body.scrollTop = 0;  //非ie
    // };
  },
  destroyed(){
    localStorage.clear();
  },
}
</script>
<style lang="scss">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}
</style>
