import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from './api/api';
import Print from 'vue-print-nb'
import $ from 'jquery';
import VueCropper from 'vue-cropper';
Vue.prototype.axios = api;
Vue.config.productionTip = false;
Vue.use(ElementUI)
Vue.use(Print)
Vue.use(VueCropper)



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
