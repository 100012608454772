import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const VueRouterPush =VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
    {
      path:'/',
      redirect:'/login'
    },
    // 登录login
    {
      path:'/login',
      name:'login',
      component:()=>import("@/views/login/login.vue"),
    },
    // 登录login
    {
      path:'/login1',
      name:'login1',
      component:()=>import("@/views/login/login1.vue"),
      redirect: {
        name:'logins'
      },
      children:[
        // 登录
        {
          path:'/logins',
          name:'logins',
          component:()=>import("@/components/logins.vue"),
        },
        //注册
        {
          path:'/reg',
          name:'reg',
          component:()=>import("@/components/reg.vue"),
        },
        //忘记密码
        {
          path:'/forget',
          name:'forget',
          component:()=>import("@/components/forget.vue"),
        },
      ]
    },
    //教师端
    {
      path:'/teacher',
      name:'teacher',
      component:()=>import("@/views/teacher/index.vue"),
      redirect: {
        name:'Lecturerend'
      },
      children:[
        // 我的课堂
        {
          path:'/Lecturerend',
          name:'Lecturerend',
          component:()=>import("@/views/teacher/Lecturerend.vue"),
        },
        {
          path:'/Detailsclassroom',
          name:'Detailsclassroom',
          component:()=>import("@/views/teacher/Detailsclassroom.vue"),
        },
        // 账号设置
        {
          path:'/AccountSettings',
          name:'AccountSettings',
          component:()=>import("@/views/teacher/AccountSettings.vue"),
        },
      ],
    },
    // 登录学生端
    {
      path:'/student',
      name:'student',
      component:()=>import("@/views/student/index.vue"),
      redirect: {
        name:'Myclass'
      },
      children:[
        // 加入课堂
        {
          path:'/Myclass',
          name:'Myclass',
          component:()=>import("@/views/student/Myclass.vue"),
        },
        // 资料库
        // {
        //   path:'/Database',
        //   name:'Database',
        //   component:()=>import("@/views/student/Database.vue"),
        // },
        //创建项目
        {
          path:'/Projectcreation',
          name:'Projectcreation',
          component:()=>import("@/views/student/Projectcreation.vue"),
        },
        // 账号设置
        {
          path:'/StudentSetting',
          name:'StudentSetting',
          component:()=>import("@/views/student/StudentSetting.vue"),
        },
        
      ]
    },
    // 创建项目详情页面
    {
      path:'/Createaproject',
      name:'Createaproject',
      component:()=>import("@/views/student/Createaproject.vue"),
    },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
